<template>
  <div id="step-sidebar-page">
    <div v-if="currentPage === 1 || currentPage === 2">
        <v-card-title class="primary--text font-weight-bold pa-0 mb-7">
            {{currentPage === 1 ? 'Select Matching Contact' : 'Selected Match'}}
        </v-card-title>
        <v-virtual-scroll height="400" item-height="115" :items="searchData">
            <template v-slot:default="{ item }">
                <v-card tile :key="item.id" class="mb-4">
                    <div @click="selectDonor(item)" class="cursor-pointer d-flex justify-space-between align-center white--text primary font-weight-bold pa-1 pl-2 pr-2">
                        <div>{{`${item.name || ''}`}}</div>
                        <v-icon :color="selectedDonor && selectedDonor.id == item.id ? '#5CBBF6' : 'white'">mdi-check-circle-outline</v-icon>
                    </div>
                    <v-card-subtitle class="pa-3  pl-2 pr-2 dark--text" >
                        <div class="d-flex justify-space-between align-center">
                            <div v-if="item.mobile">{{item.mobile}}</div>
                            <div v-if="item.suburb || item.state">{{`${item.suburb ? item.suburb +', ' : ''}${item.state || ''}`}}</div>
                        </div>
                        <div>
                            {{item.email || ' - '}}
                        </div>
                    </v-card-subtitle>
                </v-card>
            </template>
        </v-virtual-scroll>
    </div>
    <div v-else>
        <v-card-title class="primary--text font-weight-bold pa-0 mb-7">
            Incentive Partner
        </v-card-title>
        <v-card tile class="mb-5" v-if="currentIncentive">
            <div class="text-capitalize white--text primary font-weight-bold pa-1 pl-2 pr-2">
              {{ currentIncentive.heading }}
            </div>
            <v-card-subtitle class="dark--text text-center" >
              {{ currentIncentive.description }}
            </v-card-subtitle>
        </v-card>
        <v-card-title  v-if="currentTargetModel && !currentTargetModel.deleted_at" class="text-capitalize primary--text font-weight-bold pa-0 mb-5">
            Current Challenge
        </v-card-title>
        <v-card tile  v-if="currentTargetModel && !currentTargetModel.deleted_at">
            <div class="white--text primary font-weight-bold pa-1 pl-2 pr-2">
                <span v-if="currentTargetModel && currentTargetModel.type">{{(targetTypeList.find(item => item.value === currentTargetModel.type)).text}}</span>
                <span v-if="currentTargetModel && currentTargetModel.title">{{ '  -  '+ currentTargetModel.title }}</span>
            </div>
            <v-card-subtitle class="text-h6 primary--text font-weight-bold text-center" >
                <v-row class="ma-0 pa-0">
                    <v-col cols="6" class="text-break py-0 px-0 my-auto">
                        <div class="sidebar-target-title" v-if="currentTargetModel && currentTargetModel.fullTitle" v-html="currentTargetModel.fullTitle"></div>
                    </v-col>
                    <v-col cols="6" class="justify-center align-center py-0">
                        <apexchart ref="targetChart"
                            width="100%"
                            height="180"
                            type="radialBar"
                            :options="chartOptions"
                            :series="chartSeries"
                        />
                    </v-col>
                </v-row>
                <v-row class="ma-0 pa-0" justify="center">
                    <v-col cols="12" class="pa-0 text-break secondary--text" align="center" justify="center">
                        {{currentTargetModel && currentTargetModel.remaining === 0 ? 'Challenge Completed' : 'Still Remaining'}}
                        <template v-if="currentTargetModel && currentTargetModel.remaining !== 0">
                            <span class="ml-2" v-if="currentTargetModel && currentTargetModel.type && ['DM', 'GD'].includes(currentTargetModel.type)">
                                ${{ currentTargetModel.remaining }}
                            </span>
                        </template>
                    </v-col>
                </v-row>
            </v-card-subtitle>
        </v-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import globalHelper from '@/helpers/global.helper';
import { RADIAL_BAR_OPTIONS } from '@/constants/chart-options';

export default {
    name: 'StepSidebar',
    props: ["currentPage"],
    emits: ["goHome"],
    data() {
        return {
            chartOptions: RADIAL_BAR_OPTIONS,
            chartSeries: [],
        }
    },
    computed: {
        ...mapGetters(["currentTargetModel", "targetTypeList", "currentIncentive"]),

        searchData: {
            get() {
                return this.$store.getters["donorSearchLists"];
            },
            set(newValue) {
                return this.$store.commit("setSearchLists", newValue);
            },
        },
        selectedDonor: {
            get() {
                return this.$store.getters["selectedDonor"];
            },
            set(newValue) {
                return this.$store.commit("setSelectedDonor", newValue);
            },
        },
        currTargetName() {
            if (this.currentTargetModel && this.currentTargetModel.type) {
                return (this.targetTypeList.find(item => item.value === this.currentTargetModel.type)).text;
            } else {
                return '';
            }
        }
    },
    filters: {
        amPmTime: function(date) {
            return globalHelper.getMomentDatas('hh:mm A', date);
        }
    },
    watch: {
        currentPage: function(newValue) {
            if (newValue >= 3) {
                setTimeout(() => {
                    this.chartUpdate();                    
                }, 400);
            }
        }
    },
    created() {
        this.getLatestTarget();
    },
    mounted() {
        Echo.channel('target').listen('.saved',(e) => {
            this.wsTargetUpdate(e);
        });
        setTimeout(() => {
            this.chartUpdate();
        }, 400);
    },
    methods: {
        ...mapActions(["getStripePaymentMethods", "getLatestTarget"]),

        selectDonor(item) {
            if (this.selectedDonor && this.selectedDonor.id === item.id) {
                const emptyItem = this.$helpers.cloneData(item); 
                Object.keys(emptyItem).map(key => emptyItem[key] = '');
                emptyItem.phoneErr =  emptyItem.mobileErr = false;
                this.$store.commit("updateModel", emptyItem);
                this.$store.commit("setDonorExistingCards", []);
                this.selectedDonor = {};
                if (this.currentPage !== 1) {
                    this.$emit('goHome');
                }
            } else {
                this.searchData = this.searchData.filter(data => data.id === item.id);
                item.donor_infos = {};
                item.donor_infos.first_name = item.first_name;
                item.receipt_name = item.name;
                this.selectedDonor = item;
                this.$store.commit("updateModel", this.selectedDonor);
                this.$emit('next');
                if(item.stripe_customer_id){
                  this.getStripePaymentMethods(item.stripe_customer_id);
                }
            }
        },
        chartUpdate() {
            const self = this;
            if (this.$refs.targetChart && self.currentTargetModel) {
                this.$refs.targetChart.updateSeries([self.currentTargetModel.progress || 0]);
                let label = self.currentTargetModel.remaining || 0;
                this.$refs.targetChart.updateOptions({ labels: [ label ]});
            }
        },
        wsTargetUpdate(targetData) {
            targetData.deleted_at =  targetData.deleted_at ?
                globalHelper.getMomentDatas('YYYY-MM-DD hh:mm A', targetData.deleted_at) : null;
                this.$store.commit("setCurrentTargetModel", targetData);            
            if (this.currentPage >= 3) {
                setTimeout(() => {
                    this.chartUpdate();
                }, 400);
            }
        }
    },
    beforeDestroy() {
        Echo.leaveChannel('target');
    },
};
</script>

<style lang="scss">
.v-virtual-scroll {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
.v-virtual-scroll::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}
</style>
